<template>
  <div class="controls">
    <el-button class="controls__apply-changes-btn" name="apply-changes-btn" type="primary" @click="() => $emit('save')" :loading="isLoading" :disabled="!enabledSave">
      {{ $tf('apply_changes') }}
    </el-button>

    <div>
      <el-dropdown v-if="!isCreateMode" placement="bottom-start" :hide-timeout="300" @command="handleCommand">
        <el-button class="controls__commands-btn">...</el-button>
        <el-dropdown-menu slot="dropdown" size="small">
          <el-dropdown-item command="restart" v-if="!item.external_detector">{{ $tfo('restart') }}</el-dropdown-item>
          <el-dropdown-item command="reset" v-if="!item.external_detector">{{ $tfo('reset | parameters') }}</el-dropdown-item>
          <el-dropdown-item command="delete">{{ $tf('delete') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: 'controls',
  inheritAttrs: true,
  props: {
    state: Object,
    item: Object,
    isCreateMode: Boolean,
    enabledSave: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async handleCommand(v) {
      this.$emit(v);
    }
  }
};
</script>

<style lang="stylus">
.controls {
  display: flex;
  flex-direction: column;

  &__apply-changes-btn {
    margin: 20px 0 26px;
  }

  &__commands-btn {
    margin: -6px 0 10px;
  }
}
</style>
